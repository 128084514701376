<!--Sección de precios de compra de registos en WeChamber-->
<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="10" offset="1" md="8" offset-md="2">
        <v-row>
          <v-col cols="12" class="mt-12">
            <p class="text-center font-weight-medium mb-0"
              :class="$vuetify.breakpoint.xlOnly ? 'display-3' : 'display-2'">
              Módulo de eventos
            </p>
          </v-col>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-medium mb-0"
              :class="$vuetify.breakpoint.xlOnly ? 'display-1' : 'display-2'" R>
              ¡Paga solo lo que vas a <span class="secondary--text">usar</span>!
            </p>
          </v-col>
          <v-col cols="12" class="my-4 d-flex justify-center mt-0">
            <v-card color="wLightGray" height="auto" width="auto" rounded="xl" elevation="0">
              <v-card-title style="word-break: break-word" class="justify-center pb-0">
                <p class="text-center font-weight-black font-weight-small">
                  Todos los paquetes incluyen
                </p>
              </v-card-title>
              <v-list color="transparent" dense class="mx-2 pa-5 pt-0">
                <v-list-item dense v-for="item in content" :key="item" class="pa-0">
                  <v-list-item-icon class="my-auto mx-1">
                    <v-icon class="mx-2" color="blue">mdi-check-bold</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pa-0">
                    <p class="mb-0 pa-0" style="font-size: 15px">{{ item }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12">
            <p class="text-center title mb-0">
              Elige la cantidad de créditos que necesitas, los cuales se
              abonarán de inmediato en el saldo de tu tablero.
            </p>
            <!-- <p class="text-center subtitle-1 mb-0 grey--text text--darken-3">
              Todos los paquetes incluyen la función de envío de constancias y
              facturación automática de asistentes/miembros.
            </p> -->
          </v-col>
        </v-row>
        <v-row justify="center">
          <!--Sección de cartas de licencias en pantallas de menor tamaño-->
          <v-card flat v-if="$vuetify.breakpoint.smAndDown">
            <v-window v-model="qtyLicenses" class="rounded-xl">
              <v-window-item v-for="(item, i) in licenses" :key="i" class="ma-6" v-if="item.name != 'Avanzado'">
                <v-card height="auto" width="260" :color="item.color" class="rounded-xl on-hover" elevation="12">
                  <div class="pa-3">
                    <v-card-subtitle class="text-center pb-0">
                      <p class="mb-0 display-2 font-weight-bold wDarkerBlue--text">
                        {{ item.records | quantityFormat }}
                      </p>
                    </v-card-subtitle>
                    <v-card-subtitle class="text-center pt-0" :class="`${item.textColor}--text`">
                      <h3 class="font-weight-black">Créditos</h3>
                      <p style="color: black; font-weight: 600">
                        {{ item.valid }}
                      </p>
                    </v-card-subtitle>
                    <v-card-text class="text-center px-0 pt-8 pb-0">
                      <p class="pb-5" v-if="item.price == 0" style="
                          font-size: 25px;
                          font-weight: 700;
                          line-height: 0.7;
                        ">
                        $ 0.00 MXN
                      </p>
                      <p v-else-if="item.price" style="
                          font-size: 25px;
                          font-weight: bold;
                          line-height: 0.7;
                        ">
                        ${{ item.price | currency
                        }}<span style="font-size: 10px">x crédito</span><br />
                        <span v-if="item.tprice" style="font-size: 12px">${{ item.tprice | currency }}MXN total</span>
                      </p>
                      <span class="caption">+ Comisión del procesador</span>
                      <v-tooltip top :color="item.textColor + ' lighten-1'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small :color="item.textColor" icon depressed v-bind="attrs" v-on="on">
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <p class="caption black--text font-weight-bold">
                          Tarjeta: 3.6 % + $3 MXN + IVA
                          <br />
                          Oxxo: 3.6 % + $3 MXN + IVA
                          <br />
                          Transferencia: $7 MXN + IVA
                        </p>
                      </v-tooltip>
                      <br />
                      <span class="caption">Más informacion </span>
                      <a href="https://stripe.com/mx/pricing" target="_blank"
                        class="caption text-decoration-none blue--text">aquí</a>
                    </v-card-text>
                    <v-card-text class="text-center pt-0">
                      <v-icon size="80" :color="item.textColor">
                        {{ item.icon }}
                      </v-icon>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-4">
                      <v-btn to="/registro" style="text-transform: none"
                        class="accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>Comenzar</v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-window-item>
            </v-window>
            <v-card-actions class="justify-space-between">
              <v-row justify="center" class="ma-2">
                <v-item-group v-model="qtyLicenses" class="text-center" mandatory>
                  <v-item v-for="n in licenses.length - 1" :key="`btn-${n}`" v-slot="{ active, toggle }">
                    <v-btn x-large :input-value="active" icon @click="toggle"
                      :color="active ? 'wDarkerBlue' : 'wLightBlue'">
                      <v-icon>mdi-record</v-icon>
                    </v-btn>
                  </v-item>
                </v-item-group>
              </v-row>
            </v-card-actions>
          </v-card>
          <!-- / Sección de cartas de licencias en pantallas de menor tamaño-->

          <!--Sección de cartas de licencias en pantallas de mayor tamaño-->
          <v-col v-else cols="10" class="pa-7">
            <v-row class="d-flex justify-center">
              <div v-for="(item, i) in licenses" :key="i" v-if="item.name != 'Avanzado'">
                <v-hover v-slot="{ hover }">
                  <v-card height="530" width="220" :color="item.color" :elevation="hover ? 12 : 0"
                    :class="{ 'on-hover': hover }" class="rounded-xl ma-3">
                    <div class="pa-3 d-flex flex-column justify-space-between" style="height: 100%">
                      <v-card-subtitle class="text-center pb-0">
                        <p class="mb-0 display-2 font-weight-bold wDarkerBlue--text">
                          {{ item.records | quantityFormat }}
                        </p>
                      </v-card-subtitle>
                      <v-card-subtitle class="text-center pt-0" :class="`${item.textColor}--text`">
                        <h3 class="font-weight-black">Créditos</h3>
                        <p style="color: black; font-weight: 600">
                          {{ item.valid }}
                        </p>
                      </v-card-subtitle>
                      <v-card-text class="text-center px-0 pt-8 pb-0">
                        <p class="pb-5" v-if="item.price == 0" style="
                            font-size: 25px;
                            font-weight: 700;
                            line-height: 0.7;
                          ">
                          $ 0.00 MXN
                        </p>
                        <p v-else-if="item.price" style="
                            font-size: 25px;
                            font-weight: bold;
                            line-height: 0.7;
                          ">
                          ${{ item.price | currency
                          }}<span style="font-size: 10px">x crédito</span><br />
                          <span v-if="item.tprice" style="font-size: 12px">${{ item.tprice | currency }} MXN
                            total</span>
                        </p>
                        <span class="caption">+ Comisión del procesador</span>
                        <v-tooltip top :color="item.textColor + ' lighten-1'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn x-small :color="item.textColor" icon depressed v-bind="attrs" v-on="on">
                              <v-icon>mdi-information-outline</v-icon>
                            </v-btn>
                          </template>
                          <p class="caption black--text font-weight-bold">
                            Tarjeta: 3.6 % + $3 MXN + IVA
                            <br />
                            Oxxo: 3.6 % + $3 MXN + IVA
                            <br />
                            Transferencia: 2% + $7 MXN + IVA
                          </p>
                        </v-tooltip>
                        <br />
                        <span class="caption">Más informacion </span>
                        <a href="https://stripe.com/mx/pricing" target="_blank"
                          class="caption text-decoration-none blue--text">aquí</a>
                      </v-card-text>
                      <v-card-text class="text-center pt-0">
                        <v-icon size="80" :color="item.textColor">
                          {{ item.icon }}
                        </v-icon>
                      </v-card-text>
                      <v-card-actions class="justify-center pb-4">
                        <v-btn to="/registro" style="text-transform: none"
                          class="accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>Comenzar</v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-hover>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" md="10" offset-md="1">
            <p class="text-center font-weight-medium mb-0"
              :class="$vuetify.breakpoint.xlOnly ? 'display-2' : 'display-1'">
              ¿Cómo funciona?
            </p>
          </v-col>
          <v-col cols="12" md="10" offset-md="1">
            <ol>
              <li class="subtitle-1">
                Crea una cuenta en
                <span class="font-weight-bold">Comenzar.</span>
              </li>
              <li class="subtitle-1">
                Da de alta una cuenta bancaria para recibir el dinero de tus
                ventas.
              </li>
              <li class="subtitle-1">
                Crea un
                <span class="font-weight-bold"> evento</span>, comparte la liga
                y empieza a vender, cada que una persona compre un boleto se
                descontará un crédito de tu tablero.
              </li>
              <li class="subtitle-1">
                Puedes comprar un nuevo paquete en todo momento, si te sobran
                créditos puedes usarlos en nuevos eventos, tienen vigencia de un
                año.
              </li>
              <li class="subtitle-1">
                No olvides que puedes descargar reportes de ventas y asistencia,
                mandar diplomas, crear cupones de descuento, facturar
                automáticamente tus ventas, entre otras cosas, ya está incluido
                en tu paquete.
              </li>
            </ol>
          </v-col>
        </v-row>
        <!-- / Sección de cartas de licencias en pantallas de mayor tamaño-->
        <v-row class="mb-15">
          <v-col cols="12" class="mt-12">
            <p class="text-center font-weight-medium mb-0"
              :class="$vuetify.breakpoint.xlOnly ? 'display-3' : 'display-2'">
              Módulo de membresías
            </p>
          </v-col>
          <v-col cols="12" class="pa-7">
            <v-row class="d-flex justify-center">
              <div v-for="(item, i) in licenses" :key="i" v-if="item.name == 'Avanzado'">
                <v-hover v-slot="{ hover }">
                  <v-card height="auto" width="300" :color="item.color" :elevation="hover ? 12 : 0"
                    :class="{ 'on-hover': hover }" class="rounded-xl ma-3">
                    <div v-if="item.name == 'Avanzado'">
                      <v-card-subtitle style="font-size: 16px" class="text-center pa-0 pt-7 pb-1 font-weight-black">
                        Módulo de <br />
                        membresías
                      </v-card-subtitle>

                      <p class="text-center mb-0">
                        <span style="font-weight: 700; font-size: 25px">$300.00</span><br />
                        al año / por miembro
                      </p>

                      <v-list color="transparent" dense class="mx-2">
                        <v-list-item v-for="item in advancep" :key="item" class="pa-0">
                          <v-list-item-icon class="ma-auto">
                            <v-icon class="mx-2" color="green">mdi-check-bold</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="pa-0">
                            <p class="mb-0 pa-0" style="font-size: 15px">
                              {{ item }}
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-card-text class="pa-0">
                        <p class="text-center font-weight-bold mt-4 mb-2" style="font-size: 12px; line-height: 14px">
                          Costo de implementación <br />
                          (migración y capacitación)
                        </p>
                        <br />
                        <p class="text-center">
                          1 - 500 miembros <strong>$30,000.00</strong> <br />
                          500+ miembros <strong>$60.00 x miembro</strong>
                        </p>
                        <!-- <p
                          style="
                            font-size: 25px;
                            font-weight: bold;
                            line-height: 0.7;
                            text-align: center;
                          "
                        >
                          $ 300.00 MXN <br /><span style="font-size: 10px"
                            >x miembro al año</span
                          >
                        </p><br> -->

                        <div class="text-center">
                          <span class="caption">+ Comisión del procesador</span>
                          <v-tooltip top :color="item.textColor + ' lighten-1'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn x-small :color="item.textColor" icon depressed v-bind="attrs" v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <p class="caption black--text font-weight-bold">
                              Tarjeta: 3.6 % + $3 MXN + IVA
                              <br />
                              Oxxo: 3.6 % + $3 MXN + IVA
                              <br />
                              Transferencia: 2% + $7 MXN + IVA
                            </p>
                          </v-tooltip>
                          <br />
                          <span class="caption text-center">Más informacion
                            <a href="https://stripe.com/mx/pricing" target="_blank"
                              class="caption text-decoration-none blue--text">aquí</a>
                          </span>
                        </div>
                      </v-card-text>
                      <br />

                      <!-- <v-card-text class="text-center pt-0">
                        <v-icon size="60" :color="item.textColor">
                          {{ item.icon }}
                        </v-icon>
                      </v-card-text> -->

                      <v-card-actions class="justify-center d-flex flex-column pb-4">
                        <v-btn href="mailto:contacto@wechamber.mx" style="text-transform: none"
                          class="accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>Contactar
                          Ventas</v-btn>
                        <span class="caption text-center mt-2">contacto@wechamber.mx
                        </span>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-hover>
              </div>

              <div v-for="(item, i) in licenses" :key="i" v-if="false">
                <!-- v-if="item.name == 'Avanzado'" -->
                <v-hover v-slot="{ hover }">
                  <v-card height="auto" width="220" :color="item.color" :elevation="hover ? 12 : 0"
                    :class="{ 'on-hover': hover }" class="rounded-xl ma-3">
                    <div v-if="item.name == 'Avanzado'">
                      <v-card-subtitle style="font-size: 16px" class="text-center pa-0 pt-7 pb-1 font-weight-black">
                        Módulo de membresías <br />financiado
                      </v-card-subtitle>

                      <v-list color="transparent" dense class="mx-2">
                        <v-list-item v-for="item in advancep" :key="item" class="pa-0">
                          <v-list-item-icon class="ma-auto">
                            <v-icon class="mx-2" color="green">mdi-check-bold</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="pa-0">
                            <p class="mb-0 pa-0" style="font-size: 15px">
                              {{ item }}
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-card-text class="pa-0 pt-2">
                        <p style="
                            font-size: 25px;
                            font-weight: bold;
                            line-height: 0.7;
                            text-align: center;
                            margin-bottom: 0px;
                          ">
                          <span style="font-size: 10px"> Paga ahora solo</span>
                        </p>
                        <br />

                        <p style="
                            font-size: 25px;
                            font-weight: bold;
                            line-height: 0.7;
                            text-align: center;
                          ">
                          $ 60.00 MXN <br /><span style="font-size: 10px">x miembro</span>
                        </p>

                        <p style="
                            font-size: 25px;
                            font-weight: bold;
                            line-height: 0.7;
                            text-align: center;
                            margin-bottom: 0px;
                          ">
                          <span style="font-size: 10px">
                            y los $300.00 MXN restantes <br />cuando pague el
                            miembro<br />
                            <br />
                            Total $360.00 MXN al año x miembro</span><br /><br />
                        </p>
                        <div class="text-center">
                          <span class="caption">+ Comisión del procesador</span>
                          <v-tooltip top :color="item.textColor + ' lighten-1'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn x-small :color="item.textColor" icon depressed v-bind="attrs" v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <p class="caption black--text font-weight-bold">
                              Tarjeta: 3.6 % + $3 MXN + IVA
                              <br />
                              Oxxo: 3.6 % + $3 MXN + IVA
                              <br />
                              Transferencia: 2% + $7 MXN + IVA
                            </p>
                          </v-tooltip>
                          <br />
                          <span class="caption text-center">Más informacion
                            <a href="https://stripe.com/mx/pricing" target="_blank"
                              class="caption text-decoration-none blue--text">aquí</a>
                          </span>
                        </div>
                      </v-card-text>

                      <!-- <v-card-text class="text-center pt-0">
                        <v-icon size="60" :color="item.textColor">
                          {{ item.icon }}
                        </v-icon>
                      </v-card-text> -->

                      <v-card-actions class="justify-center d-flex flex-column pb-4">
                        <v-btn href="mailto:contacto@wechamber.mx" style="text-transform: none"
                          class="accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>Contactar
                          Ventas</v-btn>
                        <span class="caption text-center mt-2">contacto@wechamber.mx
                        </span>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-hover>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <landing-footer></landing-footer>
  </v-container>
</template>
<script>
import LandingFooter from '@/components/landing/LandingFooter';
export default {
  components: {
    LandingFooter,
  },
  data() {
    return {
      qtyLicenses: 0,
      licenses: [
        {
          name: 'Free',
          records: 25,
          valid: 'Gratis',
          price: 0,
          src: '',
          btn: 'Registrarme gratis',
          color: 'wLightGray',
          textColor: 'secondary',
          icon: 'mdi-account',
        },
        {
          name: 'Free',
          records: 50,
          valid: 'Válidos por un año',
          price: 32.8,
          tprice: 1640,
          src: '',
          btn: 'Registrarme gratis',
          color: 'wLightGray',
          textColor: 'secondary',
          icon: 'mdi-account',
        },
        {
          name: 'Básico',
          records: 150,
          valid: 'Válidos por un año',
          price: 27.8,
          tprice: 4170,
          src: '',
          btn: 'Quiero la licencia',
          color: 'wLightGray',
          textColor: 'secondary',
          icon: 'mdi-account-multiple',
        },
        {
          name: 'Academy',
          records: 500,
          valid: 'Válidos por un año',
          price: 24.18,
          tprice: 12090,
          src: '',
          btn: 'Quiero la licencia',
          color: 'wLightGray',
          textColor: 'secondary',
          icon: 'mdi-account-multiple',
        },
        {
          name: 'Congresos',
          records: 1000,
          valid: 'Válidos por un año',
          price: 19.79,
          tprice: 19790,
          src: '',
          btn: 'Quiero la licencia',
          color: 'wLightGray',
          textColor: 'secondary',
          icon: 'mdi-account-group',
        },
        {
          name: 'Congresos',
          records: 3000,
          valid: 'Válidos por un año',
          price: 16.5,
          tprice: 49500,
          src: '',
          btn: 'Quiero la licencia',
          color: 'wLightGray',
          textColor: 'accent',
          icon: 'mdi-account-group',
        },
        {
          name: 'Avanzado',
          color: 'wLightAccent',
          textColor: 'secondary',
          icon: 'mdi-account',
        },
      ],
      content: [
        'Creación de micrositios.',
        'Pagos en línea.',
        'Facturación automática.',
        'Constancias de participación/membresía.',
        'Tablero de control.',
        'Reportes descargables.',
        'Sistema de código QR.',
      ],
      advancep: [
        'Credenciales virtuales.',
        'Pagos de cuotas en línea.',
        'Facturación automática.',
        'Gestión de beneficios.',
        'Comunicación hacia miembros.',
        'Gestión de cursos y eventos.',
        'Tablero con indicadores y métricas.',
        // "Todas las funciones.",
        // "Gestión de capítulos / delegaciones locales.",
        // "Permisos/Roles de usuario.",
        // "Tablero para miembros.",
        // "Mi comunidad",
        // "Créditos para eventos",
      ],
    };
  },
};
</script>
<style scoped>
/* Hacer que la tarjeta se haga más grande al pasar el apuntador sobre ella */
.v-card {
  transition: all 0.6s;
}

.v-card:not(.on-hover) {
  transform: scale(0.95);
}
</style>
